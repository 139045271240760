.verificationCode {
    direction: ltr;
    width: 100%;
    .ReactInputVerificationCode__input {
        left: 0;
        top: 100px;
    }
    .ReactInputVerificationCode__container {
        width: 100%;
    }
    .ReactInputVerificationCode__item:last-child {
        margin-right: 0;
    }
    .ReactInputVerificationCode__item {
        width: 100%;
        height: 3rem;
        margin-right: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .ReactInputVerificationCode__item.is-active {
        box-shadow: inset 0 0 0 2px var(--primary-color-main) !important;
    }
}