.blockList.ant-collapse-item {
	border-radius: 8px;
}

.blockList.template-preview {
	border-bottom: 1px solid var(--block_border_color) !important;
}

.blockList .ant-collapse-header {
	flex-direction: row-reverse !important;
	align-items: center !important;
}

.blockList-bg .ant-collapse-content {
	border-top: 0 !important;
	background-color: var(--white-natural-color) !important;
}

.linkPreview .ant-collapse-content {
	border-top: 0 !important;
	background-color: var(--bg-gray-color-100) !important;
}

.blockList .ant-collapse-header .ant-collapse-expand-icon {
	padding-inline-end: 0 !important;
}

/* input */
.ant-input {
	background-color: var(--input-label-bg);
}

.ant-input-affix-wrapper > input.ant-input {
	background-color: var(--bg-natural-color-50);
}

.ant-input-number-input {
	padding: 0 !important;
}

.ant-input-affix-wrapper-rtl {
	gap: 5px;
}

.ant-input-suffix {
	gap: 3px;
}

/* drawer */
.ant-drawer-content.drawer-rounded-top {
	border-top-right-radius: 30px !important;
	border-top-left-radius: 30px !important;
}

.ant-drawer-content-wrapper {
	box-shadow: none !important;
}

.ant-drawer-mask {
	max-width: 448px;
	margin: 0 auto;
}


/* input number */

.ant-input-number-handler-wrap {
	display: none !important;
}

.ant-input-number {
	width: 100%;
}

.ant-input-number-input {
	padding: 0;
}

.suffix-icon {
	position: absolute;
	top: 10px;
	left: 10px;
}

.suffix-icon-ltr {
	position: absolute;
	top: 10px;
	right: 10px;
}

.ant-input-number-input,
.ant-input-number-input-wrap {
	height: 100%;
}
